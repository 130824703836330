import React, {useEffect} from 'react';
import styled from 'styled-components/macro';
import {useOktaAuth} from '@okta/okta-react';

import {ModalBlur} from '../Modal/ModalBlur';
import {StyledModal} from './styles';
import {EndOfServiceModalContent} from './styles';
import {ConfirmActionProps} from './types';
import userState from '../../recoil/userState';
import {StyledButton as StyledButtonCommon} from '../Button/components/SubmitButton';

const {useValue} = userState;
const storageKey = 'endOfService';

const StyledButton = styled(StyledButtonCommon)`
  &.ant-btn {
    ${({theme}) => theme.forWidth('max', 'sm', `max-width: none;`)}
  }
`;

const EndOfServiceModal = (props: ConfirmActionProps) => {
  const [show, setShow] = React.useState(false);
  const {data: userStateData, isLoading} = useValue();
  const userId = userStateData?.user?.id;

  useEffect(() => {
    const item = sessionStorage.getItem(storageKey);
    if (!isLoading && (!item || item !== userId)) {
      setShow(true);
    }
  }, [userId, isLoading]);

  const close = () => {
    if (userId) {
      sessionStorage.setItem(storageKey, userId);
    }
    setShow(false);
  };

  return (
    <>
      {show && <ModalBlur />}
      <StyledModal
        {...props}
        visible={show}
        closable={false}
        footerRenderer={() => [
          <StyledButton primaryGreen data-e2e='footer__ok-button' onClick={close}>
            OK
          </StyledButton>,
        ]}
        title='🔔 oneRx Will Be Discontinued on May 15th'
      >
        <EndOfServiceModalContent>
          <p>
            The oneRx platform will no longer be available after <b>May 15, 2025</b>. Thank you for
            being a valued oneRx user.
          </p>
        </EndOfServiceModalContent>
      </StyledModal>
    </>
  );
};

const EndOfServiceModalWrapper = (props: ConfirmActionProps) => {
  const {authState} = useOktaAuth();

  return <>{authState.isAuthenticated ? <EndOfServiceModal /> : null}</>;
};

export default EndOfServiceModalWrapper;
