import {createGlobalStyle} from 'styled-components/macro';

import {ThemeProps} from '../../theme/theme';

// TODO: should thinks how to implement this in Modal component directly to keep DRY
export const ModalBlur = createGlobalStyle<ThemeProps>`
  #root {
    filter: blur(5px);
  }
`;
