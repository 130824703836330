import styled from 'styled-components/macro';

import Modal from '../Modal';

export const EndOfServiceModalContent = styled.div`
  color: ${({theme}) => theme.colors.BLACK};
  margin-top: 25px;
`;

export const StyledModal = styled(Modal)`
  .ant-modal-header {
    .ant-modal-title {
      font-weight: 500;
      color: ${({theme}) => theme.colors.BLACK}${({theme}) => theme.colors.EIGHTY_SEVEN};
    }
  }
`;
